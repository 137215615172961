
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import Vue from "vue"
import axios from "@evercam/shared/api/client/axios"
import { useSnapshotStore } from "@evercam/dashboard/stores/snapshots"
import { useCompareStore } from "@evercam/dashboard/stores/compare"
import { mapStores } from "pinia"
import { useCameraStore } from "@evercam/dashboard/stores/camera"
import { AxiosError, CancelTokenSource } from "axios"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  NearestSnapshotResponsePayload,
} from "@evercam/shared/types"
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import { useNuxtApp } from "#app"

enum SnapshotType {
  Before = "before",
  After = "after",
}

export default Vue.extend({
  components: { EvercamLoadingAnimation },
  data() {
    return {
      noSnapshot: "/unavailable.jpg",
      snapshotLoading: { before: true, after: true },
      snapshotCancelTokens: { before: [], after: [] },
      isLoading: false,
    }
  },
  computed: {
    ...mapStores(useSnapshotStore, useCompareStore, useCameraStore),
    evercamLogo() {
      return require("~/static/evercam_white.png")
    },
    isSnapshotLoading(): boolean {
      return (
        this.snapshotLoading.before ||
        this.snapshotLoading.after ||
        this.isLoading
      )
    },
  },
  watch: {
    "compareStore.beforeTimestamp"(timestamp) {
      this.loadSnapshot(timestamp, SnapshotType.Before)
    },
    "compareStore.afterTimestamp"(timestamp) {
      this.loadSnapshot(timestamp, SnapshotType.After)
    },
  },
  async mounted() {
    await this.initLatestSnapshot()
    this.initSnapshots()
  },
  methods: {
    async initLatestSnapshot() {
      if (
        this.snapshotStore.latestSnapshotTimestamp(
          this.cameraStore.selectedCameraExid
        ) &&
        !this.cameraStore.isCameraOnline
      ) {
        return
      }
      this.isLoading = true
      await this.snapshotStore.updateLatestSnapshot(
        this.cameraStore.selectedCameraExid
      )
      this.isLoading = false
    },
    async setupInitialTimestamps() {
      if (
        !this.compareStore.afterTimestamp ||
        !this.$moment(this.compareStore.afterTimestamp).isValid()
      ) {
        this.compareStore.afterTimestamp =
          this.snapshotStore.latestSnapshotTimestamp(
            this.cameraStore.selectedCameraExid
          )
      }
      if (
        !this.compareStore.beforeTimestamp ||
        !this.$moment(this.compareStore.beforeTimestamp).isValid()
      ) {
        let nearestMilestone
        try {
          nearestMilestone = await EvercamApi.layers.getLayer(
            this.cameraStore.selectedCameraExid,
            {
              timestamp: this.compareStore.afterTimestamp,
              layerType: LAYER_TYPES.MILESTONE,
            }
          )
        } catch (error) {
          console.error(
            "Unable to retrieve milestone layers for camera",
            this.cameraStore.selectedCameraExid,
            error
          )
        }
        this.compareStore.beforeTimestamp =
          nearestMilestone?.startAt ||
          this.snapshotStore.oldestSnapshotTimestamp(
            this.cameraStore.selectedCameraExid
          )
      }
    },
    async initSnapshots() {
      const { beforeTimestamp, afterTimestamp } = this.compareStore
      if (!beforeTimestamp || !afterTimestamp) {
        await this.setupInitialTimestamps()
      }
      Promise.all([
        this.loadSnapshot(beforeTimestamp, SnapshotType.Before),
        this.loadSnapshot(afterTimestamp, SnapshotType.After),
      ])
    },
    async loadSnapshot(timestamp, snapshotType: SnapshotType) {
      if (!timestamp) {
        return
      }
      this.snapshotLoading[snapshotType] = true
      if (
        timestamp ===
        this.snapshotStore.oldestSnapshotTimestamp(
          this.cameraStore.selectedCameraExid
        )
      ) {
        this.setSnapshot(
          this.snapshotStore.oldestSnapshotImage(
            this.cameraStore.selectedCameraExid
          ),
          snapshotType
        )

        return
      }

      if (
        timestamp ===
        this.snapshotStore.latestSnapshotTimestamp(
          this.cameraStore.selectedCameraExid
        )
      ) {
        this.setSnapshot(
          this.snapshotStore.latestSnapshotImage(
            this.cameraStore.selectedCameraExid
          ),
          snapshotType
        )

        return
      }

      try {
        // Cancel the previous requests
        this.cancelRequests(snapshotType)

        // Add cancel token for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        this.addCancelToken(cancelToken, snapshotType)
        const response: NearestSnapshotResponsePayload =
          await EvercamApi.recordings.nearest(
            this.cameraStore.selectedCameraExid,
            timestamp,
            null,
            {
              cancelToken: cancelToken.token,
            }
          )
        const snapshot = response?.snapshots?.[0]?.data
        this.setSnapshot(snapshot || this.noSnapshot, snapshotType)
      } catch (error) {
        if (this.$axios.isCancel(error as AxiosError)) {
          return
        }
        this.$notifications.error({
          text: this.$t("content.generic_error_message"),
          error,
        })
        this.setSnapshot(this.noSnapshot, snapshotType)
      }
    },
    setSnapshot(snapshot, snapshotType: SnapshotType) {
      this.compareStore.snapshot[snapshotType] = snapshot
      this.snapshotLoading[snapshotType] = false
    },
    addCancelToken(token: CancelTokenSource, snapshotType: SnapshotType) {
      this.snapshotCancelTokens[snapshotType].push(token)
    },
    resetCancelTokens(snapshotType: SnapshotType) {
      this.snapshotCancelTokens[snapshotType] = []
    },
    cancelRequests(snapshotType: SnapshotType) {
      const cancelTokens = this.snapshotCancelTokens[snapshotType]
      if (cancelTokens?.length) {
        cancelTokens.forEach((t) => {
          t.cancel("Operation canceled due to new request")
        })
        this.resetCancelTokens(snapshotType)
      }
    },
    trackCompareSlide() {
      // The compare box is used in multiple pages: Compare and Timeline
      // Therefore, we need to track the event based on the page id
      const compareSlidedEventMap = {
        [AnalyticsEventPageId.Compare]: AnalyticsEvent.CompareSlide,
        [AnalyticsEventPageId.Timeline]: AnalyticsEvent.TimelineCompareSlide,
      }

      const pageId = useNuxtApp().nuxt2Context.app.head.meta.find(
        (item) => !!item?.pageId
      )?.pageId

      const eventName = compareSlidedEventMap[pageId]
      if (eventName) {
        this.$analytics.saveEvent(eventName)
      }
    },
  },
})
